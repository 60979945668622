import React from 'react'
import { FiPhone } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import { ImClock } from 'react-icons/im'
import france from '../linkImage/france.png'
const FranceWork = () => {
  return (
    <>
      {/*France*/}
      <div className="PTE2">
        <img src={france} alt="" className='img-fluid w-100' />
      </div>

      <div className='heading-linking'>
                <div className='container'>
                    <ol>
                        <li>
                            <a href='/'>Home</a>
                        </li>
                        <li>
                            <a href='/work'>Work</a>
                        </li>
                        <li>
                            <a >France</a>
                        </li>
                    </ol>
                </div>
            </div>
      {/* content */}
      <div className='main-section py-5'>
        <div className='container'>
          <div className='row g-3'>
            <div className='col-lg-9 shadow p-4 mb-5 bg-body rounded'>


              {/*Malta  work visa*/}
              <div className='pb-3'>
                <h3 className='mb-3'>France  Work Visa?</h3>
                <p>
                  There are several choices available in France for long-term work visas. If you want to stay in France for longer than three months, you must apply for one of the work visas listed below. Where a person must fulfil the requirements of a certain visa they are applying for, the eligibility requirements for these visas will differ.
                </p>
              </div>


              {/* types  */}
              <div className='pb-3'>
                <h3 className='mb-3'>Types of Work Permits in France </h3>

                <p>The types of France work permits are as follows:</p>
                <ol>
                  <li>French Salaried Employees Visa</li>
                  <li>French Work Visa for Starting and Managing a Company or Business: </li>
                  <li>French Work Visa for Contract Workers and Professionals</li>
                  <li>French long-term work visa for volunteering</li>
                  <li>French employment visa for International Organisations</li>
                </ol><br />

                <h5 className='fw-bold'>French Salaried Employees Visa</h5>
                <p>For those looking to work in France for a year or less. One must submit an employment contract that has been properly approved by the DIRECCTE when applying for this visa.</p><br />

                <h5 className='fw-bold'>French Work Visa for Starting and Managing a Company or Business</h5>
                <p> You must apply for this kind of visa if you want to start and operate a business or enterprise in France, whether on your own initiative or in cooperation with another business. Remember that you will be requested for more paperwork than normal to demonstrate your financial capability to launch the intended firm.</p><br />


                <h5 className='fw-bold'>French Work Visa for Contract Workers and Professionals:  </h5>
                <p>Some occupations, including bailiffs, notaries, court administrators, and general insurance agents, are not permitted to non-EU citizens. Others like physicians, attorneys, architects, etc. will require permission from the appropriate professional body. Therefore, before applying for this kind of visa, be sure to find out what you will need to do to practice your profession in France.</p><br />

                <h5 className='fw-bold'>French long-term work visa for volunteering: </h5>
                <p>This is a visa for those who want to perform humanitarian work in France for up to one year and more than three months. If you intend to apply for such a visa, keep in mind that you must do so through a French Foundation or Association that has been approved by the French authorities as a registered charity or public utility.</p><br />

                <h5 className='fw-bold'>French employment visa for International Organisations  </h5>
                <p>Those who wish to travel to France on official business for an international organisation must apply for this category of visa.</p>
              </div>


              {/* requirements  */}
              <div className='pb-3'>
                <h3 className='mb-3'>France Work Visa Requirements </h3>
                <ul>
                  <li>A valid passport that is valid for at least 3 months after the intended stay </li>
                  <li>A photocopy of the passport’s first and last pages</li>
                  <li>Form for applying for a visa that is filled out</li>
                  <li>Copies of all educational credentials</li>
                  <li>References from previous employers</li>
                  <li>Recently clicked coloured photos </li>
                  <li>Appointment letter from the company</li>
                  <li>Employer’s letter of recommendation</li>
                  <li>A detailed explanation of the type of work that the candidate will be doing in France</li>

                </ul>
              </div>

              {/* eligibility */}
              <div className='pb-3'>
                <h3 className='mb-3'> France  Work Visa Eligibility</h3>
               
                <ul>
                  <li>The applicant must have a valid passport.</li>
                  <li>The applicant has been offered a job in France</li>
                  <li>Must have all the documents specified in the application form</li>
                  <li>Applicants can work only within the scope of work time mentioned in their respective work permits and are not allowed to exceed the mentioned time.</li>
                </ul>

           

             
              </div>

              {/*  Benefits */}
              <div className='pb-3'>
                <h3 className='mb-3'>Benefits of France Work Visa </h3>
                <ul>
                  <li>Unemployment </li>
                  <li>Family allowances</li>
                  <li>Old age pension</li>
                  <li>Health and sickness</li>
                  <li>Invalidity benefits</li>
                  <li>Accidents and occupational disease </li>
                  <li>Death benefits</li>
                  <li>Maternity and paternity benefits</li>
                </ul>
              </div>
              {/* How to get  */}
              <div className='pb-3'>
                <h3 className='mb-3'>How to Apply for France  Work Visa</h3>
                <p>Step 1: Choose a suitable France Work Visa Scheme.</p>
                <p>Step 2: Create a case order ID</p>
                <p>Step 3: Pay the required amount for the work visa fee.</p>
                <p>Step 4: Arrange the documents required for the visa</p>
                <p>Step 5: Submit the application</p>
                <p>Step 6: Submit the biometric information</p>
                <p>Step 7: Wait for the response.</p>

              </div>
              {/* process time */}
              <div className='pb-3'>
                <h3 className='mb-3'>Processing Time for a France Work Visa</h3>
          <p>A France Work Visa for Indians usually takes 15 to 20 working days once all the required paperwork is submitted to the French Embassy or Consulate. However, depending on the type of work visa you have filed for the processing period may differ.</p>
              </div>
            </div>

            <div className='col-lg-3 '>
              <div className='border py-4 mb-3 fixtext'>
                <h3 className='text-center fw-bold text-white'>France  Work Visa</h3>

              </div>
              <div class="list-group mb-4 bg-primary">
                <a href="/UkWork" class="list-group-item list-group-item-action py-3">UK </a>
                <a href="/UsaWork" class="list-group-item list-group-item-action py-3 ">USA</a>
                <a href="/AusWork" class="list-group-item list-group-item-action py-3">Australia </a>
                <a href="/CanadaWork" class="list-group-item list-group-item-action py-3 ">Canada</a>
                <a href="/NzWork" class="list-group-item list-group-item-action py-3">New Zealand </a>
                <a href="/SingaporeWork" class="list-group-item list-group-item-action py-3 ">Singapore </a>
                <a href="/GermanyWork" class="list-group-item list-group-item-action py-3 ">Germany </a>
                <a href="/maltaWork" class="list-group-item list-group-item-action py-3 ">Malta </a>
                <a href="/polandWork" class="list-group-item list-group-item-action py-3 ">Poland </a>
                <a href="/belgiumWork" class="list-group-item list-group-item-action py-3 ">Belgium </a>

              </div>
              <div className='box border py-4 px-2 '>
                <h5 className='fw-bold'>Talk to our Immigration Experts</h5>
                <h6 className='mt-3'><FiPhone className='fs-5' />&nbsp; Phone: <a href='tel:+91-98727-33369' className='text-secondary'>+91-98727-33369</a></h6>
                <h6><FaWhatsapp className='fs-5' />&nbsp; Whatsapp: <a href='https://api.whatsapp.com/message/A475MHX2ZGQGO1?autoload=1&app_absent=0' className='text-secondary'>+91-9872833369 </a></h6>
                <hr />
                <h5 className='fw-bold mt-4'>Support</h5>
                <h6 className='text-secondary'><ImClock className='fs-5 text-dark' />&nbsp;Monday to Saturday - 9 AM to 6 PM</h6>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default FranceWork
